
import { defineComponent, ref } from 'vue'
import WorkspaceUsersModalEmails from '@/components/pages/admin/workspace/users/WorkspaceUsersModalEmails.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    WorkspaceUsersModalEmails,
  },
  setup() {
    const users = ref([
      {
        email: '',
        role: 'Administrator',
        subAccounts: 'All sub-accounts (14)',
      },
    ])

    const addUser = () => {
      users.value.push({
        email: '',
        role: '',
        subAccounts: 'All sub-accounts (14)',
      })
    }

    const removeUser = (u: any) => {
      users.value = users.value.filter(v => v !== u)
    }

    const { openModal } = useModals()
    const openWorkspaceUsersInviteModal = (hideModal: any) => {
      hideModal()
      openModal('workspaceUsersInvite')
    }

    return {
      users,
      addUser,
      removeUser,
      openWorkspaceUsersInviteModal,
    }
  },
})
