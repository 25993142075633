
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { roleOptions } from '@/definitions/_general/_data/optionsList'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
  },
  props: {
    users: {
      type: Array,
    },
    title: {
      type: String,
      default: 'Send invites to the following people',
    },
    pageStyle: {
      type: Boolean,
    },
  },
  emits: ['addUser', 'removeUser'],
  setup() {
    const subAccountsOptions = [
      'All sub-accounts (14)',
      'Robert Overit',
      'John Doe',
    ]
    const { isSmMax } = useBreakpoints()

    return {
      subAccountsOptions,
      isSmMax,
      roleOptions,
    }
  },
})
